<template>
    <div class="queues">
        <nav class="breadcrumb" aria-label="breadcrumbs">
            <ul>
                <li><router-link to="/">Dashboard</router-link></li>
                <li class="is-active"><a href="#" aria-current="page">Queues &amp Topics</a></li>
            </ul>
        </nav>

        <h1 class="title">Queues</h1>

        <article v-if="queueErrorMessage" class="message is-danger">
            <div class="message-header">
                <p>Error</p>
                <button class="delete" aria-label="delete" @click="queueErrorMessage = null"></button>
            </div>
            <div class="message-body">
                {{ queueErrorMessage }}
            </div>
        </article>

        <div v-if="isLoadingQueues" class="columns is-multiline">
            <div v-for="index in 4" :key="'skeleton' + index" class="column is-half">
                <div class="card">
                    <header class="p-2">
                        <b-skeleton size="is-large" active></b-skeleton>
                    </header>
                    <div class="card-content">
                        <div class="content">
                            <b-skeleton size="is-large mb-4"></b-skeleton>

                            <div class="columns is-multiline">
                                <div v-for="index in 6" :key="'skeletonRow' + index" class="column is-half">
                                    <b-skeleton size="is-large" height="45px"></b-skeleton>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="columns is-multiline">
            <div v-for="(queue, index) in queues" :key="'queue' + index" class="column is-half">
                <div class="card">
                    <header>
                        <p class="card-header-title">{{ queue.name }}</p>
                    </header>
                    <div class="card-content pt-0">

                        <div class="tabs is-small">
                            <ul>
                                <li :class="queue.propertiesActive ? 'is-active' : ''">
                                    <a role="button" @click="queue.propertiesActive = true; queue.processorActive = false;">Properties</a>
                                </li>
                                <li v-if="queue.proocessorOptions.processorFound" :class="queue.processorActive ? 'is-active' : ''">
                                    <a role="button" @click="queue.propertiesActive = false; queue.processorActive = true;">Processor Options</a>
                                </li>
                            </ul>
                        </div>

                        <div v-if="queue.propertiesActive" class="columns is-multiline">
                            <div class="column is-half">
                                <div class="has-accent-green has-message-padding">
                                    <p class="is-size-7">Active Messages</p>
                                    <p>{{ queue.activeMessageCount }}</p>
                                </div>
                            </div>

                            <div class="column is-half">
                                <div class="has-accent-orange has-message-padding">
                                    <p class="is-size-7">Dead-letter Messages</p>
                                    <p>{{ queue.deadLetterMessageCount }}</p>
                                </div>
                            </div>

                            <div class="column is-half">
                                <div class="has-accent-purple has-message-padding">
                                    <p class="is-size-7">Scheduled Messages</p>
                                    <p>{{ queue.scheduledMessageCount }}</p>
                                </div>
                            </div>

                            <div class="column is-half">
                                <div class="has-accent-blue has-message-padding">
                                    <p class="is-size-7">Size</p>
                                    <p>{{ humanFileSize(queue.sizeInBytes) }}</p>
                                </div>
                            </div>

                            <div class="column is-half">
                                <div class="has-accent-gray has-message-padding">
                                    <p class="is-size-7">Updated</p>
                                    <p>{{ new Date(queue.updatedAt).toLocaleString() }}</p>
                                </div>
                            </div>

                            <div class="column is-half">
                                <div class="has-accent-white has-message-padding">
                                    <p class="is-size-7">Accessed</p>
                                    <p>{{ new Date(queue.accessedAt).toLocaleString() }}</p>
                                </div>
                            </div>
                        </div>

                        <div v-if="queue.processorActive" class="columns is-multiline">
                            <div class="column is-half">
                                <div class="has-accent-green has-message-padding">
                                    <p class="is-size-7">Is Running</p>
                                    <div class="field is-grouped">
                                        <p>{{ queue.proocessorOptions.isRunning ? 'True' : 'False' }}</p>

                                        <b-tooltip label="Restart Processor" position="is-right" :delay="500">
                                            <button class="button is-ghost is-small ml-2" :class="queue.restarting ? 'is-loading' : ''" @click="restartQueueProcessor(queue)">
                                                <font-awesome-icon :icon="['fa', 'redo']" />
                                            </button>
                                        </b-tooltip>
                                    </div>
                                </div>
                            </div>

                            <div class="column is-half">
                                <p class="is-size-7 mb-1">Max Concurrent Session</p>
                                <b-numberinput size="is-small" type="is-primary" v-model="queue.proocessorOptions.maxConcurrentSessions" placeholder="8" :min="8" @input="queue.proocessorOptions.hasChanges = true"></b-numberinput>
                            </div>

                            <div class="column is-half">
                                <p class="is-size-7 mb-1">Prefetch Count</p>
                                <b-numberinput size="is-small" type="is-primary" v-model="queue.proocessorOptions.prefetchCount" placeholder="0" :min="0" @input="queue.proocessorOptions.hasChanges = true"></b-numberinput>
                            </div>

                            <div class="column is-half">
                                <p class="is-size-7 mb-1">Session Idle Timeout (s)</p>
                                <b-numberinput size="is-small" type="is-primary" v-model="queue.proocessorOptions.timeout" placeholder="0" :min="1" @input="queue.proocessorOptions.hasChanges = true"></b-numberinput>
                            </div>

                            <div class="column is-half">
                                <div class="field is-grouped">
                                    <button class="button is-small is-primary options-save-button" :disabled="!queue.proocessorOptions.hasChanges" @click="saveQueueOptions(queue)">Save</button>

                                    <p v-if="queue.proocessorOptions.saved === true" class="help is-success ml-2">Saved!</p>
                                    <p v-if="queue.proocessorOptions.saved === false" class="help is-danger ml-2">Failed!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <h1 class="title mt-5">Topics</h1>

        <article v-if="topicErrorMessage" class="message is-danger">
            <div class="message-header">
                <p>Error</p>
                <button class="delete" aria-label="delete" @click="topicErrorMessage = null"></button>
            </div>
            <div class="message-body">
                {{ topicErrorMessage }}
            </div>
        </article>

        <div v-if="isLoadingTopics">
            <div v-for="index in 4" :key="'skeleton' + index">
                <div class="card mb-5">
                    <header class="p-2">
                        <b-skeleton size="is-large" active></b-skeleton>
                    </header>
                    <div class="card-content">
                        <div class="content">
                            <div class="columns is-multiline">
                                <div v-for="index in 4" :key="'skeletonRow' + index" class="column is-half">
                                    <b-skeleton size="is-large" height="45px"></b-skeleton>
                                </div>
                            </div>
                        </div>

                        <b-skeleton size="is-large" active></b-skeleton>
                        <b-skeleton size="is-large" active></b-skeleton>
                        <b-skeleton size="is-large" active></b-skeleton>
                        <b-skeleton size="is-large" active></b-skeleton>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <div v-for="(topic, index) in topics" :key="'topic' + index">
                <div class="card mb-5">
                    <header>
                        <p class="card-header-title">{{ topic.name }}</p>
                    </header>
                    <div class="card-content pt-0">
                        <div class="columns is-multiline">
                            <div class="column is-half">
                                <div class="has-accent-purple has-message-padding">
                                    <p class="is-size-7">Scheduled Messages</p>
                                    <p>{{ topic.scheduledMessageCount }}</p>
                                </div>
                            </div>

                            <div class="column is-half">
                                <div class="has-accent-blue has-message-padding">
                                    <p class="is-size-7">Size</p>
                                    <p>{{ humanFileSize(topic.sizeInBytes) }}</p>
                                </div>
                            </div>

                            <div class="column is-half">
                                <div class="has-accent-gray has-message-padding">
                                    <p class="is-size-7">Updated</p>
                                    <p>{{ new Date(topic.updatedAt).toLocaleString() }}</p>
                                </div>
                            </div>

                            <div class="column is-half">
                                <div class="has-accent-white has-message-padding">
                                    <p class="is-size-7">Accessed</p>
                                    <p>{{ new Date(topic.accessedAt).toLocaleString() }}</p>
                                </div>
                            </div>
                        </div>

                        <table class="table is-striped is-fullwidth has-top-border has-bottom-border">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Active Messages</th>
                                    <th>Dead-letter Messages</th>
                                    <th>Accessed</th>
                                    <th>Updated</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr v-for="(subscription, index) in topic.subscriptions" :key="'subscription' + index">
                                    <td> {{ subscription.name }}</td>
                                    <td> {{ subscription.activeMessageCount }}</td>
                                    <td> {{ subscription.deadLetterMessageCount }}</td>
                                    <td> {{ new Date(subscription.accessedAt).toLocaleString() }}</td>
                                    <td> {{ new Date(subscription.updatedAt).toLocaleString() }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { secureGet, securePatch, securePost } from '../secureFetch.js';

    export default {
        name: 'Queues',
        computed: {
            hostUrl: function () {
                return this.$store.state.hostUrl;
            }
        },
        data() {
            return {
                isLoadingQueues: true,
                isLoadingTopics: true,
                queues: null,
                topics: null,
                queueErrorMessage: null,
                topicErrorMessage: null
            }
        },
        mounted() {
            this.getQueues();
            this.getTopics();
        },
        methods: {
            getQueues() {
                this.isLoadingQueues = true;

                secureGet(this.hostUrl + "v1/queue/list")
                    .then(data => {
                        this.isLoadingQueues = false;

                        if (data) {
                            if (data.success) {
                                // set additional queue data to defaults
                                data.queues.forEach(queue => {
                                    queue.restarting = false;

                                    if (queue.propertiesActive === undefined) {
                                        queue.propertiesActive = true;
                                    }

                                    if (queue.proocessorOptions) {
                                        queue.proocessorOptions.hasChanges = false;
                                        queue.proocessorOptions.timeout = this.timeoutToSeconds(queue.proocessorOptions.sessionIdleTimeout);
                                    }
                                });

                                this.queues = data.queues;
                            }
                            else {
                                this.queueErrorMessage = data.message;
                            }
                        }
                    });
            },
            getTopics() {
                this.isLoadingTopics = true;

                secureGet(this.hostUrl + "v1/topic/list")
                    .then(data => {
                        this.isLoadingTopics = false;

                        if (data && data.success) {
                            this.topics = data.topics;
                        }
                        else {
                            this.topicErrorMessage = data.message;
                        }
                    });
            },
            humanFileSize(size) {
                var i = size == 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
                return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
            },
            timeoutToSeconds(timeout) {
                return timeout / 10000000;
            },
            secondsToTimeout(seconds) {
                return seconds * 10000000;
            },
            saveQueueOptions(queue) {
                var patchRequest = {
                    maxConcurrentSessions: queue.proocessorOptions.maxConcurrentSessions,
                    prefetchCount: queue.proocessorOptions.prefetchCount,
                    sessionIdleTimeout: this.secondsToTimeout(queue.proocessorOptions.timeout)
                };

                this.queueErrorMessage = null;

                securePatch(this.hostUrl + "v1/queue/processor-options/" + queue.name, patchRequest)
                    .then(data => {
                        if (data) {
                            if (data.success) {
                                queue.proocessorOptions.isRunning = data.isRunning;
                                queue.proocessorOptions.maxConcurrentSessions = data.maxConcurrentSessions;
                                queue.proocessorOptions.prefetchCount = data.prefetchCount;
                                queue.proocessorOptions.sessionIdleTimeout = data.sessionIdleTimeout;
                                queue.proocessorOptions.timeout = this.timeoutToSeconds(data.sessionIdleTimeout);
                                queue.proocessorOptions.hasChanges = false;
                            }
                            else {
                                this.queueErrorMessage = data.message;
                            }
                        }
                    });
            },
            restartQueueProcessor(queue) {
                queue.restarting = true;

                securePost(this.hostUrl + "v1/queue/restart-processor/" + queue.name, null)
                    .then(data => {
                        queue.restarting = false;

                        if (data) {
                            if (data.success) {
                                queue.proocessorOptions.isRunning = data.isRunning;
                            }
                            else {
                                this.queueErrorMessage = data.message;
                            }
                        }
                    });
            }
        }
    };
</script>

<style scoped>
    .has-message-padding {
        padding: 0.1rem 0 0.1rem 0.5rem;
    }

    .options-save-button {
        margin-bottom: 0.1rem
    }

    .has-accent-green {
        border-left: 4px solid #1F5D51;
    }

    .has-accent-orange {
        border-left: 4px solid #A1392A;
    }

    .has-accent-purple {
        border-left: 4px solid #4C0059;
    }

    .has-accent-blue {
        border-left: 4px solid #0060AA;
    }

    .has-accent-gray {
        border-left: 4px solid #292D2F;
    }

    .has-accent-white {
        border-left: 4px solid white;
    }
</style>
